<template>
  <CContainer>
    <h1>Bienvenido a Intrack</h1>
    <PageCard :appItems="appItems" />
  </CContainer>
</template>

<script>
import { getHomePages } from '@/utils/sections.js'
import PageCard from '@/components/Cards/PageCard.vue'
import { checkUser } from "@/utils/token";

export default {
  name: "Inicio",
  components: { PageCard },
  data: () => ({
    appItems: []
  }),
  created() {
    this.setAppItems();
  },
  methods: {
    /**
     * Función para obtener los items para poder consultar las secciones.
    */
    setAppItems(){
      this.appItems = getHomePages(checkUser()?.user?.type == 'admin')
    }
  }
};
</script>